import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchApplicationUserStore = defineStore('searchApplicationUser', {
  state: () => ({
    ApplicationUser_agateId: null as number | null,
    ApplicationUser_applicationRoleId: null as number | null,
    ApplicationUser_email: null as string | null,
    ApplicationUser_status: null as string | null,
    useTerms: true,
    items: [
      { key: 'ApplicationUser_agateId', operation: ':' },
      { key: 'ApplicationUser_applicationRoleId', operation: ':' },
      { key: 'ApplicationUser_email', operation: '~' },
      { key: 'ApplicationUser_status', operation: ':' }
    ] as TermItemDefinition[]
  })
})
